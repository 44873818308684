import { AK } from "../constants/AppKeys";
import axios from "axios";
import {
  getUserdata,
  validateToken,
  getBrowser,
  AxiosError,
} from "./sessionexpiry";
import { useNavigate } from "react-router-dom";

let bower = {};
let ipinfo = {};

export const AxiosIPINFO = async () => {
  return axios
    .get(AK.IPINFOURL)
    .then((res) => {
      return res;
    })
    .catch(() => {});
};

const callpageinit = async () => {
  bower = getBrowser();
  ipinfo = await AxiosIPINFO();
};
callpageinit();

export const AxiosPost = async (apiname, payload, checktoken = true) => {
  let token = checktoken ? validateToken() : null;

  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;
  return axios
    .post(AK.APIURL + apiname, payload, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .catch((error) => {
      return AxiosError(error);
    });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosPost_Nocatch = async (
  apiname,
  payload,
  checktoken = true
) => {
  let token = checktoken ? validateToken() : null;

  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;
  return axios.post(AK.APIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const FBConversionPUSH = async (event_name) => {
  // let token = checktoken ? validateToken() : null;

  let email = "";
  let phone = "";
  let client_ip_address = "";
  let client_user_agent = "";
  let fname = "";
  let lname = "";
  let gender = "";
  let dob = "";
  let city = "";
  let state = "";
  let zip = "";
  let country = "";
  let external_id = "";

  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
    // Authorization: `Bearer ${token}`,
  };

  const payload = {
    email: getUserdata()?.email,
    phone: getUserdata()?.mobile,
    event_name: event_name,
    client_ip_address: ipinfo?.data?.ip,
    client_user_agent: JSON.stringify(bower),
    lname: getUserdata()?.name,
    // "gender": "male",
    // "dob": "1990-01-01",
    // "city": "Mumbai",
    // "state": "MH",
    // "zip": "400001",
    // "country": "IN",

    // External ID for the user
    //  "external_id": "12345",
    // Facebook click ID for tracking
    // "fbp": "fb.1.1596403881668.1116446470",

    // // Facebook browser cookie for tracking
    // "fbc": "fb.1.1558571054389.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890",

    // Subscription ID for the user
    // "subscription_id": "sub_12345",

    // Lead ID for the user
    // "lead_id": "lead_123111233345",

    // URL of the event source
    //  "event_source_url": "https://example.com/cart",

    // Order ID associated with the event
    //  "orderid": "order_12345"
  };

  // payload['storeid'] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;

  // "email": "test@example.com",
  //   "phone": "+1234567890",
  //     "event_name": "AddToCart",
  //       "client_ip_address": "106.197.111.215",
  //         "client_user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.111 Safari/537.36"

  return axios
    .post(AK.APIURL + AK.FBCONVERSIONPUSHAPI, payload, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {});
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const axiosSetWishlist = async (id, navigate) => {
  let token = true ? validateToken() : null;

  if (token === null) {
    navigate("/signin");
  }

  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    productid: id.toString(),
  };

  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;

  return axios
    .post(AK.APIURL + AK.WISHLISTPUSHAPI, payload, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => {});
};

export const axiosGetWishlist = async (setproductwishlistData) => {
  let token = true ? validateToken() : null;

  if (token !== null) {
    const headers = {
      accesskey: AK.ACCESSKEY,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const payload = {};

    payload["storeid"] = process.env.REACT_APP_STORE_ID;
    payload["storecode"] = process.env.REACT_APP_STORE_CODE;

    return axios
      .post(AK.APIURL + AK.WISHLISTFETCHAPI, payload, {
        headers,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      })
      .then((res) => {
        console.log(res);
        setproductwishlistData(res?.data?.wishlist);
        return res?.data?.wishlist;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const axiosGetOverallProductRatings = async (
  setproductoverallratingData
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  const payload = {};

  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;

  return axios
    .post(AK.APIURL + AK.OVERALLPRODUCTSRATINGAPI, payload, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((res) => {
      // console.log(res)
      // setproductwishlistData(res?.data?.wishlist)
      setproductoverallratingData(res?.data?.products);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const axiosGetProductReviews = async (
  id,
  setproductaveragerating,
  setproductreviewList
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  const payload = {
    productid: id,
  };

  payload["storeid"] = process.env.REACT_APP_STORE_ID;
  payload["storecode"] = process.env.REACT_APP_STORE_CODE;

  return axios
    .post(AK.APIURL + AK.PRODUCTREVIEWSFETCHAPI, payload, {
      headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((res) => {
      console.log(res);
      setproductaveragerating(res?.data?.average_rating);
      setproductreviewList(res?.data?.reviews);
      // setproductwishlistData(res?.data?.wishlist)
    })
    .catch((error) => {});
};

export const axiosSetProductReviews = async (id, rating, comment, files) => {
  let token = true ? validateToken() : null;

  if (token !== null) {
    const headers = {
      accesskey: AK.ACCESSKEY,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };

    const formData = new FormData();
    formData.append("rating", rating);
    formData.append("storeid", process.env.REACT_APP_STORE_ID);
    formData.append("storecode", process.env.REACT_APP_STORE_CODE);
    formData.append("productid", id);
    formData.append("comment", comment);
    if (files?.length > 0)
      files.forEach((file, index) => {
        formData.append(`files[]`, file);
      });

    return axios
      .post(AK.APIURL + AK.PRODUCTREVIEWSPUSHAPI, formData, {
        headers,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      })
      .then((res) => {
        console.log(res);
        // setproductaveragerating(res?.data?.average_rating)
        // setproductreviewList(res?.data?.reviews)
        // setproductwishlistData(res?.data?.wishlist)
      })
      .catch((error) => {});
  }
};

export const axiosClearWishlist = async (id) => {
  let token = true ? validateToken() : null;

  if (token !== null) {
    const headers = {
      accesskey: AK.ACCESSKEY,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const payload = {
      productid: id.toString(),
    };

    payload["storeid"] = process.env.REACT_APP_STORE_ID;
    payload["storecode"] = process.env.REACT_APP_STORE_CODE;

    return axios
      .post(AK.APIURL + AK.WISHLISTCLEARAPI, payload, {
        headers,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {});
  }
};

export const AxiosDirectPost = async (apiurl, payload, checktoken = true) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.post(apiurl, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosInstamojoDirectPost = async (
  apiurl,
  payload,
  checktoken = true
) => {
  const headers = {
    "X-Api-Key": "test_a15356a96ae27ef4d454f525d56",
    "X-Auth-Token": "test_a3ea1ff9524f246b516b0a9ea36",
  };

  return axios.post(apiurl, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosGET = async (
  apiname,
  payload,
  history,
  checktoken = true
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.get(AK.APIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosMockupGET = async (
  apiname,
  payload,
  history,
  checktoken = true
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.get(AK.MOCKUPSURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const ReddisAxiosGET = async (
  apiname,
  payload,
  history,
  checktoken = true
) => {
  const headers = {
    accesskey: AK.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.get(AK.REDDISAPIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosFilePost = async (
  apiname,
  payload,
  history,
  checktoken = true
) => {
  let token = checktoken ? validateToken(history) : null;
  const headers = {
    accesskey: AK.ACCESSKEY,
    accesstoken: token,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
  };

  return await axios.post(AK.APIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/homepage.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaAngleDown } from "react-icons/fa";
import { CiSearch, CiUser, CiHeart } from "react-icons/ci";
import { IoBagOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { handleGetCartInfoStorageItems } from "../utilities/cartManager";
import "./customstyle.css";
import { useNavigate, Link } from "react-router-dom";
import { getUserdata } from "../utilities/sessionexpiry";
import logo from "./Homepage/images/logo.png"

import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";






export default function NavbarMain(props) {
  const { storeid, count, setCount, wishlist, setwishlist, categoryListData } = props;
  const [cartinfoData, setCartInfoData] = useState({
    cartcount: 0,
    cartquantitycount: 0,
    products: [],
    cartprice: 0,
    subtotal: 0,
    shipping: 0,
    ordertotal: 0,
    total: 0,
  });
  const [userData, setUserData] = useState(getUserdata());
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    handleGetCartInfoStorageItems(setCartInfoData);
    setUserData(getUserdata());
    console.log(cartinfoData.cartcount);
    setCount({ ...count, count: count + 1 });
  }, [cartinfoData.cartcount]);

  useEffect(() => {
    handleGetCartInfoStorageItems(setCartInfoData);
    setUserData(getUserdata());
    console.log(count);
  }, [count]);

  const [isEnabled, setIsEnabled] = useState(true);

  const [showSearchBar, setShowSearchBar] = useState(false);

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);};

  const initializeWhatsappChatWidget = () => {
    if (!isEnabled) return;

    const isOnCheckoutPage = window.location.pathname === '/checkout';
    setIsEnabled(!isOnCheckoutPage); // Disable the widget if on checkout page

    if (isOnCheckoutPage) return;

    const url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?12205';
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    const options = {
      "enabled": isEnabled,
      "chatButtonSetting": {
        "backgroundColor": "#00e785",
        "ctaText": "Chat with us",
        "borderRadius": "25",
        "marginLeft": "0",
        "marginRight": "20",
        "marginBottom": "20",
        "ctaIconWATI": false,
        "position": "right"
      },
      "brandSetting": {
        "brandName": "A & G",
        "brandSubTitle": "A & G",
        "brandImg": "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/8/logo.webp",
        "welcomeText": "Hi there!\nHow can I help you?",
        "messageText": "Hello. We would want to avail your services. Kindly get in touch with A & G",
        "backgroundColor": "#00e785",
        "ctaText": "Chat with us",
        "borderRadius": "25",
        "autoShow": false,
        "phoneNumber": "919176395559"
      }
    };
    script.onload = () => {
      window.CreateWhatsappChatWidget(options);
    };
    document.body.appendChild(script);
  };

  const [pageinit, setPageInit] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };
  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
  };
  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
  };
  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen5);
  };
  if (!pageinit) {
    setPageInit(true);
    setUserData(getUserdata());
    initializeWhatsappChatWidget(isEnabled);
  }

  const getcartCount = () => {
    return cartinfoData.cartcount;
  };
  const cartCount = cartinfoData.cartcount;

  const handleBurgerClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCloseClick = () => {
    setIsMobileMenuOpen(false);
  };


 const mainCategories = categoryListData
    ?.sort((a, b) => {
      return a.sortorder - b.sortorder;
    })
    .filter((category) => category.parent_id === 0);
  // Then, sort the main categories based on their IDs
  mainCategories?.sort((a, b) => a.id - b.id);
  console.log(mainCategories);

  const [visibleSubcategories, setVisibleSubcategories] = useState([]);

  // Function to toggle visibility of subcategories
  const toggleSubcategories = (mainCategoryId) => {
    if (visibleSubcategories.includes(mainCategoryId)) {
      setVisibleSubcategories(
        visibleSubcategories.filter((id) => id !== mainCategoryId)
      );
    } else {
      setVisibleSubcategories([...visibleSubcategories, mainCategoryId]);
    }
  };

  const [hoveredCategory, setHoveredCategory] = useState(null);

  // Function to handle mouse enter and leave events
  const handleMouseEnter = (mainCategoryId) => {
    setHoveredCategory(mainCategoryId);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
    setHoveredSubCategory(null);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const navigate2 = useNavigate();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate2(`/products?search=${encodeURIComponent(searchQuery)}`);
    setShowSearchBar(false);
  };

  const [hoveredSubCategory, setHoveredSubCategory] = useState(null);

  const getSubcategoriesByParentId = (parentId) => {
    return categoryListData?.filter(
      (category) => category.parent_id === parentId
    );
  };
  const getSubcategoriesBySubcatagory = (parentId) => {
    return categoryListData?.filter(
      (category) => category.parent_id === parentId
    );
  };


  // const [searchQuery, setSearchQuery] = useState("");
  // const navigate2 = useNavigate();

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  // const handleSearchSubmit = (e) => {
  //   e.preventDefault();
  //   navigate2(`/products?search=${encodeURIComponent(searchQuery)}`);
  // };



  return (
    <div>
      <div className="home">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      </div>
      <div className="nav-all">
        <div className="navbar">
          <div className="container">
            <ul className="nav-list">
              <li><Link to="/">Home</Link></li>
                {mainCategories?.map(mainCategory => (
                        <div key={mainCategory.id}>
                          <a className="ms-3 " href={`/products/${mainCategory.seo_url}`} onClick={() => toggleSubcategories(mainCategory.id)}>
                            {mainCategory.name}  {getSubcategoriesByParentId(mainCategory.id)
                                .length > 0 &&
                                (visibleSubcategories.includes(
                                  mainCategory.id
                                ) ? (
                                  <FontAwesomeIcon
                                    icon={faCaretDown}
                                    size="sm"
                                    style={{
                                      color: "#ffbda2",
                                      marginLeft: "5px",
                                    }}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    // icon={faCaretRight}
                                    size="sm"
                                    style={{
                                      color: "#ffbda2",
                                      marginLeft: "5px",
                                    }}
                                  />
                                ))}
                          </a>
                          {visibleSubcategories.includes(mainCategory.id) &&
                            categoryListData
                              .filter(subCategory => subCategory.parent_id === mainCategory.id)
                              .map(subCategory => (
                                <div key={subCategory.id}>
                                  {/* Subcategory */}
                                  <a className="ms-3 submobilemenu" href={`/products/${subCategory.seo_url}`}>
                                    {subCategory.name}
                                  </a>
                                </div>
                              ))}
                        </div>
                      ))}
             
            </ul>
            <div className="nav-icons">
              <ul className="icons-list">
                <li onClick={toggleSearchBar} style={{ cursor: 'pointer' }} >
                  <CiSearch />
                </li>
                {showSearchBar && (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // dark overlay
              backdropFilter: 'blur(0.5px)', // blur effect
              zIndex: 999, // make sure it's on top
            }}
            onClick={toggleSearchBar} // Click outside to close the search bar
          />

          {/* Centered Search Bar */}
          <div
            style={{
              position: 'fixed',
              top: '33%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000, // above the overlay
            }}
          >

<style jsx>{`
            @media (max-width: 600px) {
              div[style*='top: 20%'] {
                top: 0%; 
                width: 250px;
                max-width: 80%;
                padding: 5px 8px;
              }
            }

            @media (max-width: 768px) {
              div[style*='top: 20%'] {
                top: 18%; 
                width: 280px;
                max-width: 85%;
                padding: 5px 9px; 
              }
            }
          `}</style>

                        <CiSearch onClick={handleSearchSubmit} size={20} style={{ marginRight: '8px', color: '#333',position:'relative',top:'35px',left:'260px' }} />



<form
 
  onSubmit={handleSearchSubmit}>

  <input
    type="text"
    placeholder="Search..."
    style={{
      width: '300px',
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '28px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}
    onChange={handleSearchChange}

  />
  
</form>



          </div>



          
          </>
      )}

                <li className="flex">
                            <CiUser />

                  <a
                    className="signin"
                    href="/signin"
                    style={{
                      display: userData != null ? "none" : "flex",
                    }}
                  >
                    <span>SIGN&nbsp;IN</span>
                   
                  </a>
                   <span className="signin d-block d-md-none"  style={{
                      display: userData != null ? "flex" : "none",
                    }} >{userData?.name}</span>
                  <a
                    className="dropdown signin"
                    href={() => {
                      return false;
                    }}
                    style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer",
                    }}
                  >
                    <span className="d-none d-md-block">{userData?.name}</span>
                    <div className="dropdown-content ">
                      <Link to="/myprofile" style={{ cursor: "pointer" }}>
                        My Profile
                      </Link>
                      <Link to="/myaccount" style={{ cursor: "pointer" }}>
                        My Account
                      </Link>
                      <Link to="/orderhistory" style={{ cursor: "pointer" }}>
                        My Orders
                      </Link>
                      <hr
                        style={{
                          border: "1px solid #CFD5E2",
                          height: "2px",
                        }}
                      />
                      <Link to="/signin">Logout</Link>
                    </div>
                  </a>
                </li>
                <li>
                   <Link to={!wishlist ? "/mywishlist" : "/products/gifts"}
                        onClick={() => {
                        setwishlist(!wishlist); setCount({
                          ...count,
                          count: count + 1,
                        });
                      }} >
                    <CiHeart style={{ color: wishlist ? 'red' : 'inherit' }} />
                  </Link>
                </li>
                <li>
                  <Link to="/cart" style={{position:"relative"}}>
                                        {cartCount === 0 ? (
                        <span className="cartCount" style={{ display: "none" }}>
                          {cartCount}
                        </span>
                      ) : (
                        <span className="cartCount">{cartCount}</span>
                      )}

                    <IoBagOutline />
                  </Link>
                </li>
                <li onClick={handleBurgerClick}>
                  <RxHamburgerMenu />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={`mobile-menu ${isMobileMenuOpen ? "open" : ""}`}>
        <div className="close-btn" onClick={handleCloseClick}>
          &times;
        </div>
        <ul>

        <div className="logo-container">
        <img src={logo} alt="Logo"  className="nav-logo" />
        </div>

           
        <h3 style={{ color: '#c32929', fontSize: '24px', paddingLeft:'30px',paddingBottom:'20px' }}>Shop In </h3>
          <li><Link to="/">Home</Link></li>
          {mainCategories?.map(mainCategory => ( <li><a href={`/products/${mainCategory.seo_url}`}>{mainCategory.name}</a></li>))}
         <div  style={{
                      display: userData != null ? "block" : "none",
                    }}>
          <h3 style={{ color: '#c32929', fontSize: '24px', paddingLeft:'30px',paddingBottom:'0px',paddingTop:'40px' }}>My Account </h3>
                    <ul>
              <li><a href="/orderhistory">My Orders</a> </li>
              <li><a href="/mywallet">My Wallet</a></li>
              <li><a href="/myaddress">My Address</a></li>
              <li><a href="/myprofile">My Profile</a></li>
            </ul>
            </div>
          <div className="contact-us">
          <h3 style={{ color: '#c32929', fontSize: '24px', paddingLeft:'30px',paddingBottom:'0px',paddingTop:'40px' }}>Contact Us </h3>
            <ul>
              <li>
               Help & Support
              </li>
              <li>
                Feedback & Suggestions
              </li>
              <li>Request a cell</li>
               <li  style={{
                      display: userData != null ? "block" : "none",
                    }}>
              <a href="/signin"><b>LOG OUT</b></a> 
              </li>
            </ul>
            <div className="nav-social">
        <FaFacebook />
        <FaInstagramSquare />
        <FaXTwitter />
        <FaYoutube />
        <FaPinterest />

        </div>
          </div>

         

          
        </ul>

       
         
      </div>
    </div>
  );
}

// import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/homepage.css";
import toast, { Toaster } from "react-hot-toast";
import FadeLoader from "react-spinners/FadeLoader";
import { AxiosPost } from "../utilities/axioscall";
import { AK } from "../constants/AppKeys";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import tele from "./Homepage/images/telegram.svg";
import logo from "./Homepage/images/footerlogo.png";

export default function Footer(props) {
  const { storeid, count, setCount, wishlist, setwishlist, categoryListData } = props;
  const mainCategories = categoryListData
    ?.sort((a, b) => {
      return a.sortorder - b.sortorder;
    })
    .filter((category) => category.parent_id === 0);
  // Then, sort the main categories based on their IDs
  mainCategories?.sort((a, b) => a.id - b.id);
  console.log(mainCategories);


  const [formValues, setFormValues] = useState({
    newsemail: "",
    newsemailError: false,
    formloading: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    var error = false;



    if (formValues.newsemail == "") {
      handleChange(
        {
          target: {
            value: true,
            name: "newsemailError"
          }
        }
      )

      error = true;
    } else {
      handleChange(
        {
          target: {
            value: false,
            name: "newsemailError"
          }
        }
      )
    }


    if (error) return;
    // Handle form submission logic here
    // Example: Logging form data
    console.log(formValues);


    handleChange(
      {
        target: {
          value: true,
          name: "formloading"
        }
      }
    )
    var formdata = {


      "email": formValues.newsemail,
    }
    return await AxiosPost(AK.NEWSLETTERFORMAPI, formdata)
      .then((res) => {

        if (res != typeof undefined && res.data != typeof undefined) {
          if (res?.data) {
            notify()
            setFormValues({
              newsemail: "",
              newsemailError: false,
              formloading: false
            })
            handleChange(
              {
                target: {
                  value: false,
                  name: "formloading"
                }
              }
            )

            return true;
          } else {
            notifyerror("Newsletter validation error.")

          }
        }



        handleChange(
          {
            target: {
              value: false,
              name: "formloading"
            }
          }
        )
        return true;
      })
      .catch((error) => {
        console.log(error)
        // notifyerror("Newsletter validation error.")


        handleChange(
          {
            target: {
              value: false,
              name: "formloading"
            }
          }
        )
        return false;
      });
    // Optionally, you can redirect after form submission
    // navigate("/thank-you"); // Replace "/thank-you" with your desired route
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const notify = () =>
    toast.success("Newsletter subscribed successfully.", {
      style: {
        border: "1px solid #00b852",
        padding: "16px",
        color: "#00b852",
      },
      iconTheme: {
        primary: "#00b852",
        secondary: "#FFFAEE",
      },
    });

  const notifyerror = (msg) =>
    toast.error(msg, {
      style: {
        border: "1px solid #cc0000",
        padding: "16px",
        color: "#cc0000",
      },
      iconTheme: {
        primary: "#cc0000",
        secondary: "#FFFAEE",
      },
    });


  return (
    <div className="footer-main mt-4">
      <div className="row container mx-auto">
        <div className="col-md-3 col-12">
          <div className="footer-first ps-4 ps-md-0">
            <h6><img src={logo}></img>Nalls Shoppy</h6>
            <p>Lorem ipsum dolor sit amet. Et accusantium omnis qui dolore debitis ex doloribus quas es.</p>
            <div>

            </div>
          </div>
        </div>
        <div className="col-md-3 col-12">
          <ul>
            <li>Services</li>
            <li><FontAwesomeIcon icon={faCheck} style={{ color: "#83cd20", }} /> Gardening</li>
            <li><FontAwesomeIcon icon={faCheck} style={{ color: "#83cd20", }} /> Marketing Course</li>
            <li><FontAwesomeIcon icon={faCheck} style={{ color: "#83cd20", }} /> Product</li>
            <li><FontAwesomeIcon icon={faCheck} style={{ color: "#83cd20", }} /> Online Course</li>
          </ul>
        </div>
        <div className="col-md-3 col-12">
          <ul>
            <li>Useful Link</li>
            <li><FontAwesomeIcon icon={faAngleRight} style={{ color: "#83cd20", }} /> Courses</li>
            <li><FontAwesomeIcon icon={faAngleRight} style={{ color: "#83cd20", }} /> Products</li>
            <li><FontAwesomeIcon icon={faAngleRight} style={{ color: "#83cd20", }} /> Testimonials</li>
            <li><FontAwesomeIcon icon={faAngleRight} style={{ color: "#83cd20", }} /> Contact</li>
          </ul>
        </div>
        <div className="col-md-3 col-12 newsletter ps-4 ps-md-0">
          <h6>Subscribe Our Newsletter</h6>
          <p>Lorem ipsum dolor sit amet. Etomnisa ccusantium omnis qui dolore</p>
          <div>
            <form >
              <input type="text" id="newsemail"
                name="newsemail"
                value={formValues.newsemail}
                onChange={handleChange} placeholder="Enter Email" />
              <button type="button"
                onClick={(e) => {
                  handleSubmit(e);
                }}><img src={tele} /></button>
            </form> <FadeLoader loading={formValues.formloading} width={"2px"} height={"10px"} color="rgb(84 117 229)" />
          </div>
          <p
            className="mb-2 text-red-500"
            hidden={
              !formValues.newsemailError
            }
          >
            Please enter your email.
          </p>
        </div>
        <Toaster />
      </div>
      <hr />
      <div className="copyrights">
        <p>© Nalls Shoppy  2024 | All Rights Reserved</p>
        <div className="d-flex  items-center">

          <a href="/pages/contactus">Terms & Condition </a>
          <a >Privacy and Policy</a>
          <a>About Us</a>
        </div>
      </div>

    </div>
  )
}

import React, { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { PiCurrencyInr } from "react-icons/pi";
import SyncLoader from "react-spinners/SyncLoader";
import FadeLoader from "react-spinners/FadeLoader";
import OTPInput, { ResendOTP } from "otp-input-react";
import { TiTickOutline } from "react-icons/ti";
import { BsCartCheckFill } from "react-icons/bs";
import moment, { now } from "moment";
import {
  handleCartCartInfoMinus,
  handleCartCartInfoPlus,
  handleCartMinus,
  handleCartPlus,
  handleCartRemoveAll,
  handleFetchCategoryData,
  handleFetchProductsData,
  handleGetCartInfoStorageItems,
  handleSetCartInfoStorageItems,
} from "../utilities/cartManager";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import "./customstyle.css";
import { AxiosError, getUserdata } from "../utilities/sessionexpiry";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoBagCheckOutline } from "react-icons/io5";
import { checkerArray, lowercasenosp } from "../utilities/checker";
import {
  AxiosDirectPost,
  AxiosInstamojoDirectPost,
  AxiosPost,
  FBConversionPUSH,
} from "../utilities/axioscall";
import { AK } from "../constants/AppKeys";
import { callStores, get, put } from "../utilities/storeManager";
import { SK } from "../constants/StorageKeys";
import { checkerString, checkerStringLen } from "../utilities/checker";
import { useDispatch } from "react-redux";
import { setUser } from "../store/user/actions";
import Footer from "./footer";
import NavbarMain from "./navbarmain";
import CheckBoxPage from "./checkbox";
import {
  handleGetCouponInfoStorageItems,
  handleGetInfoStorageItems,
  handleSetCouponInfoStorageItems,
  handleSetInfoStorageItems,
} from "../utilities/storageManager";

const meta = {
  title: "Order Success",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export const Ordersuccess = (props) => {
  const {
    store,
    description,
    assets,
    storeid,
    storecode,
    hdimage,
    productimage,
    productviewimage,
    thumbnailviewimage,
    assetsUrl,
    categoryListData,
    userData,
    setUserData,
    versionmanagerListData,
    colorcodesListData,
    productscolorcodesListData,
    sizeListData,
    productssizeListData,
    productsListData,
    cartcouponlist,
    setcartcouponlist,
    displaycartcouponlist,
    setdisplaycartcouponlist,
    cartinfoData,
    setCartInfoData,
    count,
    setCount,
    clearCartInfoData,
    pageRefresh,
    wishlist,
    setwishlist,
    footercopyrighttext,
    showFooter,
    setshowFooter,
  } = props;
  const [storeinfo, setStoreInfo] = useState({
    storeid: null,
    store: "",
    title: "",
    description: "",
    assets: "",
    hdimage: "",
    productimage: "",
    productviewimage: "",
    thumbnailviewimage: "",
  });
  const navigate = useNavigate();
  const [pageinit, setPageInit] = useState(false);
  const [showFormLoader, setFormLoader] = useState(false);
  const [formdisplay, setFormDisplay] = useState([]);
  const [exdeliveryaddress, setExDeliveryAddress] = useState([]);
  const [exdeliveryaddshow, setExDeliveryAddshow] = useState(false);
  const [coffeeloader, setcoffeeloader] = useState(true);
  const [processingpaymentloader, setprocessingpaymentloader] = useState(false);
  const [showstatus, setShowstatus] = useState(0);

  const [pickdeliveryaddress, setpickdeliveryaddress] = useState("");
  const [timerresendsms, settimerresendsms] = useState(30);
  const [shippingrate, setShippingRate] = useState([]);
  const [shippingcalc, setShippingCalc] = useState(0);
  const [checkboxpayment, setcheckboxpayment] = useState(false);
  const [isCheckedWallet, setIsCheckedWallet] = useState(false);

  let formdisplayvalue = {
    loginorsignup: "loginorsignup",
    loginorsignupcontent: "loginorsignupcontent",
    verifywithotp: "verifywithotp",
    verifywithotpcontent: "verifywithotpcontent",
    checkoutsignin: "checkoutsignin",
    deliverycontent: "deliverycontent",
    paymentcontent: "paymentcontent",
    confirmcontent: "confirmcontent",
    ordersummarycontent: "ordersummarycontent",
  };
  // loginorsignup
  // loginorsignupcontent
  // verifywithotp
  // verifywithotpcontent
  // checkoutsignin
  // deliverycontent
  // paymentcontent
  // confirmcontent
  // ordersummarycontent

  const [checkoutDeliveryformData, setCheckoutDeliveryFormData] = useState({
    firstname: "",
    lastname: "",
    country: "",
    city: "",
    pincode: "",
    emailaddress: "",
    phonenumber: "",
    otpinput: "",
    doorno_streetaddress: "",
    location_town_district: "",
    primary_addr: 1,
    firstnameError: false,
    lastnameError: false,
    countryError: false,
    cityError: false,
    pincodeError: false,
    doorno_streetaddressError: false,
    location_town_districtError: false,
    emailaddressError: false,
    phonenumberError: false,
    otpinputError: false,
    otpinputformError: false,
    otpinputformErrorMsg: "Invalid OTP!",
    otploader: false,
    verifyotploader: false,
    loginorsignupformError: false,
    requestotploading: false,
    otpverifyloading: false,
    formError: false,
    formloading: false,
    formsuccessmsg: false,
    messageError: false,
    message: "",
  });

  const [checkoutPaymentformData, setCheckoutPaymentFormData] = useState({
    onlinepayment: false,
    banktransfer: false,
    upitransfer: false,
    razorpaytransfer: true,
    phonepetransfer: false,
    wallettransfer: false,
    phonepetransfer: false,
    onlinepaymentError: false,
    banktransferError: false,
    upitransferError: false,
    razorpaytransferError: false,
    phonepetransferError: false,
    requiredError: false,
    formError: false,
    formloading: false,
    formsuccessmsg: false,
  });

  // Enter Kay Function Start
  const [otpVerificationInitiated, setOtpVerificationInitiated] =
    useState(false);

  const handleVerifyOTP = async () => {
    if (await verifyotp()) {
      setFormDisplay([
        formdisplayvalue.checkoutsignin,
        formdisplayvalue.deliverycontent,
      ]);
      setCount({
        ...count,
        count: count + 1,
      });
      window.scrollTo(10, 0);
    }
  };

  const handleClick = async () => {
    if (await loginorsignup()) {
      setCount({
        ...count,
        count: count + 1,
      });
      setTimeout(() => {
        setFormDisplay([
          formdisplayvalue.verifywithotp,
          formdisplayvalue.verifywithotpcontent,
        ]);
        setCount({
          ...count,
          count: count + 1,
        });
        window.scrollTo(10, 0);
      }, 1000);
      setOtpVerificationInitiated(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!otpVerificationInitiated) {
        handleClick();
      } else {
        handleVerifyOTP();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [otpVerificationInitiated]);

  // Enter Kay Function END

  const [orderdetails, setOrderDetails] = useState({});

  useEffect(() => {
    console.log("products : " + cartinfoData.total);
    setprocessingpaymentloader(false);
    if (cartinfoData.cartcount > 5) {
      console.log("***");
    } else {
      console.log("***");
    }
  }, [cartinfoData.cartcount, cartinfoData, cartinfoData.products]);

  useEffect(() => {
    console.log("***");
  }, [count]);

  const getcartCount = () => {
    // return cartinfoData.cartcount;
    return cartinfoData.cartcount;
  };

  const handleOrderPayment = async (directwallet = null) => {
    // if (!userData?.name) {
    //   navigate("/signin");
    //   return;
    // }
    // console.log("checkoutPaymentformData.wallettransfer")
    // console.log(checkoutPaymentformData.wallettransfer)
    setprocessingpaymentloader(true);

    // console.log("**********");
    //deliveryaddress: JSON.stringify(checkoutDeliveryformData),
    // cartinfoData['total'] = getfinalamount();
    console.log("getfinalamount()");
    console.log(getfinalamount(isCheckedWallet));
    let payload = {
      storeid: storeid,
      storecode: storecode,
      purpose: "purpose orderpayment",
      phone: checkoutDeliveryformData.phonenumber,
      buyer_name: checkoutDeliveryformData.firstname,
      redirect_url: "https://tamiltshirts.pages.dev/checkout",
      send_email: false,
      webhook: "https://tamiltshirts.pages.dev/checkout",
      send_sms: false,
      email: checkoutDeliveryformData.emailaddress,
      allow_repeated_payments: false,
      deliveryaddress: JSON.stringify(checkoutDeliveryformData),
      cartinfodata: JSON.stringify(cartinfoData),
      totalamount: getfinalamount(),
      amount: getfinalamount(),
      deliverychg: 0,
      name: checkoutDeliveryformData.firstname,
      mobile: checkoutDeliveryformData.phonenumber,
      Pending: "pending",
      shthd: "success",
      pmthd: "online",
      is_wallet: isCheckedWallet,
      wallet: getfinalamount(),
    };

    // totalamount: getfinalamount(),
    //   amount: getfinalamount(),

    // let payload1 = {
    //   "storeid": "2",
    //   "purpose": "purpose orderpayment",
    //   "amount": 1,
    //   "phone": "8122512122",
    //   "buyer_name": "Testing",
    //   "redirect_url": "https://tamiltshirts.pages.dev/checkout",
    //   "send_email": false,
    //   "webhook": "https://tamiltshirts.pages.dev/checkout",
    //   "send_sms": false,
    //   "email": "rahman@vilvanetworks.com",
    //   "allow_repeated_payments": false,
    //   "deliveryaddress": "123 Main St, City, Country",
    //   "cartinfodata": "{\"cartcount\":1,\"cartquantitycount\":0,\"products\":[{\"imageurl\":\"t/m/tm-rn-tli-blk_1.jpg\",\"id\":1,\"storeid\":2,\"products_id\":1,\"category_id\":30,\"size_id\":\"1,2,3,4,5,6\",\"colorcodes_id\":8,\"weight_id\":0,\"products_group_id\":1,\"quantity\":100,\"mrp\":499,\"sp\":1,\"sortorder\":0,\"city_shipping\":null,\"state_shipping\":null,\"national_shipping\":null,\"delivery_time\":null,\"status\":\"001\",\"is_featured\":null,\"created_at\":\"2023-12-16 19:58:28\",\"updated_at\":\"2024-03-26 16:51:24\",\"customizable\":0,\"variation\":\"1,2\",\"name\":\"Tamizhi - Black\",\"eng_name\":null,\"sku\":\"TM-RN-TZ-Black\",\"descp\":\"<ul>\\n  <li>தமிழி(TAMIZHI)</li>\\n  <li>Bio Washed & Pre shrink</li>\\n  <li>Hand & Machine Wash</li>\\n  <li>7 days Return & Exchange Policy</li>\\n</ul>\\n\",\"shrtdesc\":\"\",\"seo_url\":\"tamizhi-black\",\"weight\":null,\"category_storeid\":2,\"category_subcat\":1,\"category_is_featured\":null,\"category_name\":\"ROUND NECK HALF SLEEVE TSHIRTS\",\"category_description\":null,\"category_seo_url\":null,\"category_meta_tag_title\":null,\"category_meta_tag_desc\":null,\"category_meta_tag_keywords\":null,\"category_cat_img\":null,\"category_parent_id\":18,\"category_sortorder\":0,\"category_status\":1,\"category_created_at\":\"2023-12-21 13:16:56\",\"category_updated_at\":\"2023-12-21 13:16:56\",\"images\":[\"t/m/tm-rn-tli-blk_1.jpg\"],\"selectedSizes\":[\"L\",\"L\"],\"addtocart\":true,\"cartquantity\":\"1\"}],\"cartprice\":0,\"subtotal\":1,\"mrptotal\":499,\"shipping\":0,\"ordertotal\":0,\"total\":1}",
    //   "totalamount": 1,
    //   "deliverychg": 49,
    //   "name": "Testing",
    //   "mobile": "8122512122",
    //   "Pending": "pending",
    //   "shthd": "success",
    //   "pmthd": "online"
    // }

    // payload = {
    //   storeid: storeid,
    storecode: storecode,
      //   amount: cartinfoData.total,
      //   deliveryaddress: "123 Main St, City, Country",
      //   cartinfodata: [
      //     {
      //       product_id: 1,
      //       quantity: 2,
      //     },
      //     {
      //       product_id: 2,
      //       quantity: 1,
      //     },
      //   ],
      //   totalamount: 1300,
      //   wallet: "wallet_id_123",
      //   deliverychg: 10,
      //   name: "Rahman",
      //   mobile: "1234567890",
      //   Pending: "pending_value",
      //   shthd: "shthd_value",
      //   pmthd: "pmthd_value",
      //   email: "rahman@vilvanetworks.com",
      // };

      // console.log(checkoutPaymentformData.banktransfer);
      // console.log(checkoutPaymentformData.upitransfer);
      // console.log(checkoutPaymentformData.onlinepayment);
      //https://mercury-t2.phonepe.com/transact/pg?token=MTA1ZTY4NGQyMmU2ZjI0NzA3OTAwNzAzMDUzNjUxMzllZTFlNjhiZWZlZjQ4NGM1MTg2YTBjZjhjMGNhNWYyYjQzOmI5YWRiOGRjM2Q3YjVlMTBhODg3Y2ZiYWU0ZjEzYTEz
      // console.log(AK.WALLETSUBMITAPI)

      // console.log(AK.WALLETSUBMITAPI)
      // console.log(checkoutPaymentformData.wallettransfer)

      await userCartInfoFetch();

    if (directwallet) {
      return await AxiosPost(AK.WALLETSUBMITAPI, payload, true)
        .then((res) => {
          // console.log(res?.data)
          if (res != typeof undefined && res.data != typeof undefined) {
            // console.log(res?.data)
            if (res?.data?.datas?.redirecturl != "") {
              // setUserData(userData?.wallet - getfinalamount())
              userData["wallet"] = userData?.wallet - getfinalamount();
              dispatch(setUser(userData));
              // clearCartInfoData();
              window.location.href = res?.data?.datas?.redirecturl;
              // window.location.replace(res.data.datas.redirecturl);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // checkoutDeliveryformData.messageError = true;
          // checkoutDeliveryformData.message = error?.message;
          changeInput_CPFD(true, "messageError");
          changeInput_CPFD(error?.response?.data?.message, "message");
          setprocessingpaymentloader(false);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    }

    if (checkoutPaymentformData.phonepetransfer) {
      return await AxiosPost(AK.PHONEPEAPI, payload, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            console.log(res?.data);
            // console.log(res?.data?.data?.data?.payment_link);
            if (res?.data?.redirect_url != "") {
              //   clearCartInfoData();
              console.log(res?.data?.redirect_url);
              window.location.href = res?.data?.redirect_url;
              // window.location.replace(res?.data?.data?.data?.payment_link);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    } else if (checkoutPaymentformData.upitransfer) {
      return await AxiosPost(AK.HAODAPAYUPI, payload, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            // console.log(res?.data?.data?.data?.payment_link);
            if (res?.data?.data?.data?.payment_link != "") {
              clearCartInfoData();
              window.location.href = res?.data?.data?.data?.payment_link;
              // window.location.replace(res?.data?.data?.data?.payment_link);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    } else if (checkoutPaymentformData.onlinepayment) {
      return await AxiosPost(AK.INSTAMOJOAPI, payload, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            if (res?.data?.datas?.redirecturl != "") {
              clearCartInfoData();
              window.location.href = res?.data?.datas?.redirecturl;
              // window.location.replace(res.data.datas.redirecturl);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setprocessingpaymentloader(false);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    } else if (
      checkoutPaymentformData.wallettransfer ||
      checkoutPaymentformData.razorpaytransfer
    ) {
      return await AxiosPost(AK.RAZORPAYAPI, payload, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            var rzpaykey = res.data.datas;
            var options = {
              key: rzpaykey["key"],
              amount: rzpaykey["amount"],
              currency: rzpaykey["currency"],
              name: rzpaykey["name"],
              description: rzpaykey["description"],
              image: rzpaykey["image"],
              order_id: rzpaykey["order_id"],
              callback_url: rzpaykey["callback_url"],
              prefill: {
                name: rzpaykey["prefill"]["name"],
                email: rzpaykey["prefill"]["email"],
                contact: rzpaykey["prefill"]["contact"],
              },
              notes: {
                address: rzpaykey["notes"]["address"],
              },
              theme: {
                color: rzpaykey["theme"]["color"],
              },
            };
            console.log(options);
            var rzp1 = new Razorpay(options);
            console.log(rzp1);
            // clearCartInfoData();
            rzp1.open();
            setprocessingpaymentloader(false);
            rzp1.on("payment.failed", function (response) {
              // alert(response.error.code);
              // alert(response.error.description);
              // alert(response.error.source);
              // alert(response.error.step);
              // alert(response.error.reason);
              // alert(response.error.metadata.order_id);
              // alert(response.error.metadata.payment_id);
            });

            // document.getElementById('positiveBtn').onclick = function (e) {
            //   window.location.href = window.location.host;
            //   e.preventDefault();
            // }

            // if (res?.data?.datas?.redirecturl != "") {
            //   clearCartInfoData();
            //   window.location.href = res?.data?.datas?.redirecturl;
            //   // window.location.replace(res.data.datas.redirecturl);
            // }
          }
        })
        .catch((error) => {
          console.log(error);
          setprocessingpaymentloader(false);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    }
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const fetchOrderPayment = async () => {
    // if (!userData?.name) {
    //   navigate("/signin");
    //   return;
    // }

    let orderid = searchParams.get("orderid");
    if (!checkerString(orderid)) {
      return false;
    }

    let payload = {
      orderid: orderid.replace("ORDER", ""),
    };

    return await AxiosPost(AK.VIEWORDERBYIDAPI, payload, true)
      .then(async (res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          if (res?.data?.datas.length > 0) {
            // setFormDisplay([
            //   formdisplayvalue.checkoutsignin,
            //   formdisplayvalue.confirmcontent,
            // ]);
            setOrderDetails(res?.data?.datas[0]);
            handleSetCartInfoStorageItems({
              cartcount: 0,
              cartquantitycount: 0,
              products: [],
              cartprice: 0,
              subtotal: 0,
              shipping: 0,
              ordertotal: 0,
            });
            clearCartInfoData(count);
            console.log();
            handleCartRemoveAll(
              setCartInfoData,
              clearCartInfoData,
              setCount,
              count
            );
            setCount({ ...count, count: count + 1 });
            userCartInfoFetchClear();
            await FBConversionPUSH("Order Successpage");
            return true;
          }
        }
      })
      .catch((error) => {
        clearCartInfoData();
        return false;
      });
  };

  const fetchDeliveryAddress = async () => {
    // if (!userData?.name) {
    //   navigate("/signin");
    //   return;
    // }
    return await AxiosPost(AK.FETCHDELIVERYADDRESSAPI, {})
      .then((res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          if (res?.data?.datas.length > 0) {
            let _exadd = res?.data?.datas;
            console.log(res?.data);
            let _wallet = res?.data?.wallet?.toString();
            console.log(_wallet);
            console.log(userData);
            // Object.assign(userData, {wallet:  _wallet.toString()});
            //  var _userData = userData;
            // _userData['wallet'] =  _wallet.toString();
            // setUserData(_userData);
            // setUserData(...userData, {wallet:  _wallet.toString()});
            // dispatch(setUser(_userData));
            if (!_exadd[0]?.primary_addr) {
              _exadd[0]["primary_addr"] = 1;
            }

            // if (!_exadd[0]) {
            if (_exadd.length > 0) {
              setpickdeliveryaddress(_exadd[0]);
              setCheckoutDeliveryFormData(_exadd[0]);

              setFormDisplay([
                formdisplayvalue.checkoutsignin,
                formdisplayvalue.paymentcontent,
              ]);
            }

            console.log(_exadd);

            // }

            setExDeliveryAddress(_exadd);
            setExDeliveryAddshow(true);

            setCount({ ...count, count: count + 1 });
            return true;
          }
        }
        setExDeliveryAddshow(false);

        return true;
      })
      .catch((error) => {
        console.log("***error***");
        console.log(error);
        return false;
      });
  };

  let dispatch = useDispatch();

  const getfinalamount = (damt = false) => {
    if (damt) {
      return userData?.wallet > cartinfoData.total + shippingcalc
        ? 0
        : cartinfoData.total + shippingcalc - userData?.wallet;
    } else {
      return cartinfoData.total + shippingcalc;
    }
    // return cartinfoData.total + shippingcalc;
  };

  const loginorsignup = async () => {
    let error = false;
    changeInput(false, "phonenumberError");

    if (!checkerString(checkoutDeliveryformData.phonenumber)) {
      error = true;
      changeInput(true, "phonenumberError");
    }

    if (!checkerStringLen(checkoutDeliveryformData.phonenumber, 10)) {
      error = true;
      changeInput(true, "phonenumberError");
    }

    var phoneno = /^\d{6,25}$/;
    if (!checkoutDeliveryformData.phonenumber.match(phoneno)) {
      error = true;
      changeInput(true, "phonenumberError");
    }

    if (error) return false;

    const requestotppayload = {
      mobile: checkoutDeliveryformData.phonenumber,
      storeid: storeid,
      storecode: storecode,
    };
    await changeInput(true, "otploader");
    await changeInput(false, "requestotploading");
    return await AxiosPost(AK.REQUESTOTPAPI, requestotppayload, false)
      .then(async (res) => {
        changeInput(true, "requestotploading");

        if (res != typeof undefined && res.data != typeof undefined) {
          dispatch(setUser(null));
          // console.log(res.data);
          return true;
        }
      })
      .catch(async (error) => {
        let errors = AxiosError(error);
        await changeInput(false, "otploader");
        await changeInput(true, "otpinputformError");
        return false;
      });
  };

  const verifyotp = async () => {
    let error = false;

    changeInput(false, "otpinputError");

    if (!checkerString(checkoutDeliveryformData.otpinput)) {
      error = true;
      changeInput(true, "otpinputError");
    }

    if (!checkerStringLen(checkoutDeliveryformData.otpinput, 4)) {
      error = true;
      changeInput(true, "otpinputError");
    }

    if (error) return false;
    const requestotppayload = {
      mobile: checkoutDeliveryformData.phonenumber,
      otp: checkoutDeliveryformData.otpinput,
    };
    await changeInput(true, "verifyotploader");
    await changeInput(false, "requestotploading");
    return await AxiosPost(AK.VERIFYOTPAPI, requestotppayload, false)
      .then(async (res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          changeInput(true, "requestotploading");
          let userdata = JSON.parse(JSON.stringify(res.data));
          //console.log(userdata);
          setcoffeeloader(true);
          dispatch(setUser(userdata));
          setUserData(userData);
          setCount({ ...count, count: count + 1 });
          await fetchDeliveryAddress();
          setcoffeeloader(false);
          setCount({ ...count, count: count + 1 });
          pageRefresh();
          // window.location.reload();
          return true;
        }
      })
      .catch(async (error) => {
        console.log(error);
        let errors = AxiosError(error);
        await changeInput(errors?.message, "otpinputformErrorMsg");
        await changeInput(false, "verifyotploader");
        await changeInput(true, "otpinputformError");
        return false;
      });
  };

  const handleDeliveryAddress = async () => {
    // if (!userData?.name) {
    //   navigate("/signin");
    //   return;
    // }

    let error = false;

    changeInput(false, "firstnameError");
    changeInput(false, "lastnameError");
    changeInput(false, "countryError");
    changeInput(false, "cityError");
    changeInput(false, "pincodeError");
    changeInput(false, "doorno_streetaddressError");
    changeInput(false, "location_town_districtError");
    changeInput(false, "emailaddressError");
    changeInput(false, "phonenumberError");

    if (!checkerString(checkoutDeliveryformData.firstname)) {
      error = true;
      changeInput(true, "firstnameError");
    }

    if (!checkerString(checkoutDeliveryformData.lastname)) {
      error = true;
      changeInput(true, "lastnameError");
    }

    if (!checkerString(checkoutDeliveryformData.country)) {
      error = true;
      changeInput(true, "countryError");
    }

    if (!checkerString(checkoutDeliveryformData.city)) {
      error = true;
      changeInput(true, "cityError");
    }

    if (!checkerString(checkoutDeliveryformData.pincode)) {
      error = true;
      changeInput(true, "pincodeError");
    }

    if (!checkerString(checkoutDeliveryformData.doorno_streetaddress)) {
      error = true;
      changeInput(true, "doorno_streetaddressError");
    }

    if (!checkerString(checkoutDeliveryformData.location_town_district)) {
      error = true;
      changeInput(true, "location_town_districtError");
    }

    if (!checkerString(checkoutDeliveryformData.phonenumber)) {
      error = true;
      changeInput(true, "phonenumberError");
    }
    if (!checkerString(checkoutDeliveryformData.emailaddress)) {
      error = true;
      changeInput(true, "emailaddressError");
    } else {
      var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(checkoutDeliveryformData.emailaddress)) {
        error = true;
        changeInput(true, "emailaddressError");
      }
    }
    var phoneno = /^\d{6,25}$/;
    if (!checkoutDeliveryformData.phonenumber.match(phoneno)) {
      error = true;
      changeInput(true, "phonenumberError");
    }

    if (error) return false;

    if (!checkoutDeliveryformData["primary_addr"])
      checkoutDeliveryformData["primary_addr"] = 1;

    checkoutDeliveryformData["primary_addr"] = !checkoutDeliveryformData[
      "primary_addr"
    ]
      ? 1
      : checkoutDeliveryformData["primary_addr"] === "HOME"
        ? 1
        : checkoutDeliveryformData["primary_addr"] === "OFFICE"
          ? 2
          : checkoutDeliveryformData["primary_addr"] === "OTHER"
            ? 3
            : 1;

    put(SK.DELIVERYCONTENTINFODATA, checkoutDeliveryformData);

    return await AxiosPost(
      AK.SUBMITDELIVERYADDRESSAPI,
      checkoutDeliveryformData
    )
      .then(async (res) => {
        console.log(res);
        if (res != typeof undefined && res.data != typeof undefined) {
          console.log(res);
          setExDeliveryAddress(res?.data?.datas);
          if (res?.data?.datas.length > 0) {
            setpickdeliveryaddress(res?.data?.datas[0]);
            setCheckoutDeliveryFormData(res?.data?.datas[0]);
          }
          //setUserData(userData);
          setCount({ ...count, count: count + 1 });

          // pageRefresh();
          return true;
        }
      })
      .catch(async (error) => {
        console.log(error);
        let errors = AxiosError(error);

        return false;
      });
  };

  var timeLeft = 30;

  //var elem = document.getElementById("some_div");

  const countdown = () => {
    if (timeLeft == -1) {
      clearTimeout(timerId);
      //  doSomething();
    } else {
      // elem.innerHTML = timeLeft + " seconds remaining";
      timeLeft--;
      settimerresendsms(timeLeft);
    }
  };

  var timerId = setInterval(countdown, 1000);

  const doSomething = () => {
    // alert("Hi");
  };

  const changeInput = useCallback(async (value, field) => {
    checkoutDeliveryformData[field] = value;
    setCheckoutDeliveryFormData(checkoutDeliveryformData);
    setCount((count) => (count = count + 1));
  });

  const changeInput_CPFD = useCallback(async (value, field) => {
    checkoutPaymentformData[field] = value;
    setCheckoutPaymentFormData(checkoutPaymentformData);
    setCount((count) => (count = count + 1));
  });

  // const userCartInfoFetch = async () => {
  //   AxiosPost(
  //     AK.USERCARTINFOAPI, JSON.stringify(cartinfoData),
  //     true
  //   ).then((res) => {
  //     if (res != typeof undefined && res.data != typeof undefined) {
  //       console.log(res.data)
  //       // setCartInfoData(_cartinfo);
  //       // setCount({ ...count, count: count + 1 });
  //     }
  //   })
  //     .catch((error) => {
  //       console.log(error);
  //       return false;
  //     });
  // }

  const userCartInfoFetch = async () => {
    //console.log(cartinfoData)
    if (cartinfoData.products.length > 0) {
      AxiosPost(AK.USERCARTINFOFETCHPUSHAPI, cartinfoData, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            console.log(res.data);
            // setCartInfoData(res.data);
            // setCount({ ...count, count: count + 1 });
          }
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    } else {
      AxiosPost(AK.USERCARTINFOAPI, {}, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            console.log(res.data);
            setCartInfoData(res?.data?.cartinfo);
            handleSetCartInfoStorageItems(res?.data?.cartinfo);
            setCount({ ...count, count: count + 1 });
          }
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    }
  };

  const userCartInfoFetchClear = async () => {
    let cartinfodefalults = {
      cartcount: 0,
      cartquantitycount: 0,
      products: [],
      cartprice: 0,
      subtotal: 0,
      mrptotal: 0,
      shipping: 0,
      ordertotal: 0,
      total: 0,
    };
    AxiosPost(AK.USERCARTINFOFETCHCLEARAPI, cartinfodefalults, true)
      .then((res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          console.log(res.data);
          // setCartInfoData(_cartinfo);
          // setCount({ ...count, count: count + 1 });
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  const callinitfetching = async (oneloader = true) => {
    setcoffeeloader(true);

    if (await fetchOrderPayment()) {
      setcoffeeloader(false);
      //  navigate("/orderhistory")
    }
    setcoffeeloader(false);
    setCount({ ...count, count: count + 1 });
  };

  const callpageInit = async () => {
    let version = handleGetInfoStorageItems(SK.VERSIONINFODATA);
    let hardreset = false;
    let mockupdata = false;
    if (
      version === null ||
      version === undefined ||
      isNaN(Number(version)) ||
      Number(version) === null ||
      Number(version) === undefined ||
      Number(process.env.REACT_APP_VERSION) !== Number(version)
    ) {
      // console.log("mockup true");
      handleSetInfoStorageItems(
        SK.VERSIONINFODATA,
        Number(process.env.REACT_APP_VERSION)
      );
      setCount({ ...count, count: count + 1 });
      mockupdata = true;
    }

    // setAssetsUrl(await callStores("assets", hardreset, true));
    let storedetails = await callStores(null, hardreset, mockupdata);
    // setStoreInfo(storedetails);
    // console.log(storedetails)
    const shippingConfig = storedetails.shipping_config;
    const statusvalue = JSON.parse(shippingConfig).methods[0].status;

    console.log("status" + statusvalue); // Output: "1"
    setShowstatus(statusvalue);
  };

  if (!pageinit) {
    setPageInit(true);
    callpageInit();
    setUserData(getUserdata());
    callinitfetching();
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}>
          <script
            src="https://checkout.razorpay.com/v1/checkout.js"
            defer
          ></script>
        </Helmet>
      </HelmetProvider>
      <>
        <NavbarMain
          storeid={storeid}
          count={count}
          setCount={setCount}
          categoryListData={categoryListData}
        />
        <div>
          {" "}
          <div className="w-full mb-5">
            <br />
            <br />
            <center>
              <img
                style={{ width: "auto" }}
                src={`yofte-assets/loader/order-placed.webp`}
                alt={`${lowercasenosp(store)} loader`}
              />
              {/* <BsCartCheckFill
        color="#7fa37f"
        style={{
          height: "100px",
          width: "100px",
        }}
      /> */}
              <br />
              <h3>
                <b>Thank you for shopping!</b>
              </h3>
              <p>
                Your order has been placed. <br />
              </p>
              <br />
              <p>
                Total Amount: ₹ {orderdetails.totalamt}
                <br />
              </p>
              <br />
              <button
                style={{ backgroundColor: "#5475E5" }}
                className="block w-72 py-2  text-center text-white font-heading uppercase rounded-md transition duration-200"
                onClick={() => {
                  // setFormDisplay([
                  //   formdisplayvalue.checkoutsignin,
                  //   formdisplayvalue.ordersummarycontent,
                  // ]);
                  navigate("/orderhistory");
                  setCount({
                    ...count,
                    count: count + 1,
                  });
                  window.scrollTo(10, 0);
                }}
              >
                View Order
              </button>
            </center>
          </div>
        </div>
      </>
      <Footer categoryListData={categoryListData} />
    </React.Fragment>
  );
};

export const SK = Object.freeze({
  CARTINFODATA: "cartinfoData",
  CATEGORYINFODATA: "categoryinfoData",
  COUPONINFODATA: "couponinfoData",
  PRODUCTSINFODATA: "productsinfoData",
  SIZEINFODATA: "sizeinfoData",
  COUNTRYINFODATA: "countryinfoData",
  WEIGHTINFODATA: "weightinfoData",
  VARIATIONSINFODATA: "variationsinfoData",
  PRODUCTSSIZEINFODATA: "productssizeinfoData",
  PRODUCTSCOLORCODESINFODATA: "productscolorcodeinfoData",
  COLORCODESINFODATA: "colorcodeinfoData",
  VERSIONMANAGERINFODATA: "versionmanagerinfoData",
  VERSIONINFODATA: "versioninfoData",
  STORESMANAGERINFODATA: "storesmanagerinfoData",
  DELIVERYCONTENTINFODATA: "deliverycontentinfoData",
});
